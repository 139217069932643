<template>
  <div class="survey-journal">
    <CustomerJourneyMapProblemComposer
      @completed="completeCustomerJourney"
    ></CustomerJourneyMapProblemComposer>
  </div>
</template>

<script>
import CustomerJourneyMapProblemComposer from "@/components/CustomerJourneyMap/problem/composer";
import BaseJournal from "@/components/Journal/BaseJournal.vue";

export default BaseJournal.extend({
  name: "CustomerJourneyJournal",
  components: { CustomerJourneyMapProblemComposer },
  data() {
    return {};
  },

  mounted() {},

  methods: {
    completeCustomerJourney(result) {
      this.doJournal(JSON.stringify(result.data, null, 3));
    }
  }
});
</script>

<style>
h4.sv_page_title {
  display: none;
}

.sv_body {
  text-align: left;
}

.sv_body h3 {
  padding: 15px;
}

.sv_p_root > div:not(.sv_row),
.sv_row,
.sv_nav {
  padding-left: 10px;
  padding-right: 10px;
}

.sv_cancel_btn {
  margin-left: 10px;
}

h5.sv_q_title .sv_q_num,
h5.sv_q_title .sv_q_num + span {
  display: none;
}

.sv_q_title {
  font-size: 1em;
}

.sv_q_description {
  font-style: italic;
  font-size: 0.9em;
  margin-bottom: 10px;
}

.sv_body textarea {
  border-radius: 8px;
  padding: 5px;
  width: 96%;
}
</style>
